html {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar       {background-color:rgba(0,0,0,0);width:8px}
body::-webkit-scrollbar-track {background-color: rgba(255, 255, 255, 1)
}
body::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:8px;border:2px solid rgba(0,0,0,0)}

div::-webkit-scrollbar       {background-color:rgba(0,0,0,0);width:8px}
div::-webkit-scrollbar-track {background-color: rgba(255, 255, 255, 1)
}
div::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:8px;border:2px solid rgba(0,0,0,0)}

.text-link {
  font-weight: bold;
  color: #000;
  transition: color .2s ease-in-out;
  cursor: pointer;
}

.text-link:hover {
  color: #2395b1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (min-width: 768px) {
  .hideOnLarge {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .hideOnSmall {
    display: none;
  }
}

@font-face {
  font-family: 'Peace Sans';
  src: url("../../static/media/Peace Sans.1dfba3db.ttf") format('truetype')
}@font-face {
  font-family: 'HVD Poster Clean';
  src: url("../../static/media/HVD Poster Clean Regular.2d1e5f83.ttf") format('truetype')
}@font-face {
  font-family: 'HVD Poster';
  src: url(../../static/media/HVDPoster.c2891888.ttf) format('truetype')
}@font-face {
  font-family: 'PFAgoraSlabProReg';
  src: url(../../static/media/PFAgoraSlabProReg.d9e98320.ttf) format('truetype')
}@font-face {
  font-family: 'LemonTuesday';
  src: url("../../static/media/Lemon Tuesday.35147b2d.otf") format('truetype')
}@font-face {
  font-family: 'BERNIERDistressed';
  src: url(../../static/media/BERNIERDistressed-Regular.f53c1a56.otf) format('truetype')
}


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #ffde1d;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}
@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}
