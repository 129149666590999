html {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar       {background-color:rgba(0,0,0,0);width:8px}
body::-webkit-scrollbar-track {background-color: rgba(255, 255, 255, 1)
}
body::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:8px;border:2px solid rgba(0,0,0,0)}

div::-webkit-scrollbar       {background-color:rgba(0,0,0,0);width:8px}
div::-webkit-scrollbar-track {background-color: rgba(255, 255, 255, 1)
}
div::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:8px;border:2px solid rgba(0,0,0,0)}

.text-link {
  font-weight: bold;
  color: #000;
  transition: color .2s ease-in-out;
  cursor: pointer;
}

.text-link:hover {
  color: #2395b1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (min-width: 768px) {
  :global .hideOnLarge {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  :global .hideOnSmall {
    display: none;
  }
}

@font-face {
  font-family: 'Peace Sans';
  src: url('assets/fonts/Peace Sans.ttf') format('truetype')
}@font-face {
  font-family: 'HVD Poster Clean';
  src: url('assets/fonts/HVD Poster Clean Regular.ttf') format('truetype')
}@font-face {
  font-family: 'HVD Poster';
  src: url('assets/fonts/HVDPoster.ttf') format('truetype')
}@font-face {
  font-family: 'PFAgoraSlabProReg';
  src: url('assets/fonts/PFAgoraSlabProReg.ttf') format('truetype')
}@font-face {
  font-family: 'LemonTuesday';
  src: url('assets/fonts/Lemon Tuesday.otf') format('truetype')
}@font-face {
  font-family: 'BERNIERDistressed';
  src: url('assets/fonts/BERNIERDistressed-Regular.otf') format('truetype')
}

